import React, { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import { graphql, PageProps } from 'gatsby';
import { processArticleContent, processHTMLContent } from '../../utils/processHTMLContent';
import Seo from '../../components/Seo';
import { getAuthorDetails, getFaqs, getRelatedPosts, getTemplateName } from '../../utils/helpers';
import { getSpecialOffer } from '../../utils/helpers/card';
import Article from '../../components/Article/Article';
import { getComments } from '../../utils/helpers/comments';
import PageHeader from '../../components/PageHeader';
import { HT_DEFAULT_PAGE_CATEGORY, HT_DEFAULT_PAGE_NAME } from '../../constants';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface IDataTemplate {
  page: Queries.WpPage;
}

interface IPageContextTemplate {
  sidebar: Queries.WpUpOption;
  databaseId: number;
  tags: any;
  link: string;
  categories: any;
  specialOffer?: number;
}

const WpDefaultPageTemplate = ({
  data,
  pageContext,
  location,
}: PageProps<IDataTemplate, IPageContextTemplate>) => {
  const {
    page: {
      content = '',
      title,
      author,
      databaseId,
      cardMentionsAll,
      nonAffiliateAll,
      fieldsForPosts,
      featuredImage,
      modifiedGmt,
      dateGmt,
      commentCount,
      commentStatus,
      comments: postComments,
      uri,
    },
  } = data;
  const sidebarGeneralSettings = pageContext?.sidebar;
  const numberOfComments = commentCount ? commentCount : 0;
  const comments = getComments(postComments, databaseId);
  const toggleElements: string[] = fieldsForPosts?.upgpPostToggleElements
    ? (fieldsForPosts?.upgpPostToggleElements as string[])
    : [];
  const image: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> | null =
    featuredImage?.node?.sourceUrl
      ? {
          src: featuredImage.node.sourceUrl,
          alt: title || '',
        }
      : null;
  const templateName = getTemplateName(undefined, uri, image);
  const { articleContent } = processArticleContent(content || '', '/TE/', templateName);
  const authorDetails = getAuthorDetails(author?.node);
  const faqs = getFaqs(fieldsForPosts?.upgpPostFaqs);
  const relatedPosts = getRelatedPosts(fieldsForPosts?.upgpPostRelatedPosts);
  const overrideSidebar = fieldsForPosts?.upgpOverrideDefaultSidebar ?? false;
  const sidebarPost = fieldsForPosts?.upgpCategorySidebarBlocks;
  const specialOffer = getSpecialOffer(pageContext?.specialOffer);
  const hasTrustDropdown = fieldsForPosts?.upgpUseTrustDropdown;

  if (uri === '/testimonials/') {
    return (
      <HTPageWrapper
        category={HT_DEFAULT_PAGE_CATEGORY}
        title={title || HT_DEFAULT_PAGE_NAME}
        location={location}
      >
        <main>
          <PageHeader title={title || ''} />
          <div className="container">{processHTMLContent(content || '')}</div>
        </main>
      </HTPageWrapper>
    );
  }

  if (uri !== '/home/' && uri !== '/') {
    return (
      <HTPageWrapper
        category={HT_DEFAULT_PAGE_CATEGORY}
        title={title || HT_DEFAULT_PAGE_NAME}
        location={location}
      >
        <Article
          articleHeader={{
            title: title || '',
            author: authorDetails,
            featuredImage: image,
            dateModified: modifiedGmt || '',
            datePublished: dateGmt || '',
          }}
          articleContent={articleContent}
          template={templateName}
          sidebarData={{
            articleId: databaseId,
            sidebar: sidebarGeneralSettings,
            categories: [],
            overrideSidebarPost: overrideSidebar,
            sidebarPost: sidebarPost,
          }}
          authorInfo={{
            authorName: authorDetails.name,
            content: authorDetails.bio || '',
            link: authorDetails.url,
            socials: authorDetails.socials,
            thumbnail: {
              src: authorDetails.imageUrl,
              alt: authorDetails.imageAlt,
            },
          }}
          cardMentions={cardMentionsAll}
          nonAffiliate={nonAffiliateAll}
          faqs={{
            faqs,
          }}
          pageId={databaseId}
          comments={{
            total: numberOfComments,
            comments,
            postDatabaseId: databaseId,
            commentsClosed: commentStatus === 'closed',
          }}
          weRecommend={specialOffer}
          relatedPosts={{
            relatedPosts,
          }}
          toggleElements={toggleElements}
          showTrustDropdown={hasTrustDropdown || false}
          context={{
            databaseId: data?.page?.databaseId || 0,
            tags: null,
            link: data?.page?.uri || '',
            categories: null,
            templateName: data?.page?.template?.templateName || '',
          }}
        />
      </HTPageWrapper>
    );
  }

  return (
    <HTPageWrapper
      category={HT_DEFAULT_PAGE_CATEGORY}
      title={title || HT_DEFAULT_PAGE_NAME}
      location={location}
    >
      {processHTMLContent(content || '')}
    </HTPageWrapper>
  );
};

export default WpDefaultPageTemplate;

export const Head = ({ data }: PageProps<IDataTemplate, IPageContextTemplate>) => {
  const { seo } = data.page;
  return (
    <>
      <Seo wpSeo={seo} />
    </>
  );
};

export const pageQuery = graphql`
  fragment PageData on WpPage {
    databaseId
    title
    content
    featuredImage {
      node {
        sourceUrl
      }
    }
    featureImageCredit {
      upgpPostFeaturedImageCreditUrl
      upgpPostFeaturedImageCreditValue
    }
    uri
    commentCount
    commentStatus
    comments {
      nodes {
        id
        parentId
        author {
          node {
            url
            name
            email
            avatar {
              url
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        content
      }
    }
    modifiedGmt
    dateGmt
    author {
      node {
        databaseId
        description
        postsCount
        name
        uri
        avatar {
          url
        }
        userBios {
          upgpUserShortBio
        }
        userSocials {
          upgpUserEmail
          upgpUserSocialLinks {
            upgpUserSocialLinksName
            upgpUserSocialLinksUrl
          }
        }
        upgpAuthorDetails {
          upgpEditorCustomExcerpt
          upgpCountriesVisited
          upgpUsStatesVisited
          upgpEditorJobTitle
          upgpAuthorCountEditedPosts
        }
      }
    }
    cardMentionsAll
    nonAffiliateAll
    fieldsForPosts {
      upgpPostFaqs {
        ... on WpPage_Fieldsforposts_UpgpPostFaqs_UpgpPostFaq {
          upgpPostAnswer
          upgpPostQuestion
        }
      }
      upgpPostRelatedPosts {
        fieldGroupName
        upgpGbPostRelatedPostsPostPage {
          ... on WpPost {
            id
            title
            uri
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
      upgpUseTrustDropdown
      upgpPostToggleElements
      upgpOverrideDefaultSidebar
      upgpCategorySidebarBlocks {
        upgpCategorySibebarBlockName
        upgpCategorySibebarBlockLinks {
          customLinkTitle
          link {
            ... on WpPost {
              databaseId
              title
              uri
            }
          }
        }
      }
    }
    seo {
      title
      metaRobotsNofollow
      metaRobotsNoindex
      canonical
      metaDesc
      opengraphDescription
      opengraphImage {
        sourceUrl
        mediaType
        mediaDetails {
          height
          width
        }
      }
      opengraphType
      opengraphTitle
      opengraphUrl
      opengraphSiteName
      opengraphPublisher
      opengraphModifiedTime
      readingTime
      schema {
        raw
      }
    }
    template {
      templateName
    }
  }
  query Page($id: String) {
    page: wpPage(id: { eq: $id }) {
      ...PageData
    }
  }
`;
